import React from 'react';
import { Trans } from 'react-i18next';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUISelect from '@mui/material/Select';
import { withStyles } from '@mui/styles';
import FormHelperText from '@mui/material/FormHelperText';

const styles = theme => ({
    menuItemRoot: {
        whiteSpace: 'break-spaces',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    selectPaper: {
        maxWidth: 500
    }
});

const Select = ({
    value, handleChange, options,
    label, className, name, classes,
    helperText, error, helperTextClassName,
    sx
}) => {
    return (
        <FormControl variant="outlined" className={className}>
            <InputLabel
                htmlFor="outlined-simple"
                sx={{ background: '#fff', p: '0 5px' }}
            >
                <Trans>{label}</Trans>
            </InputLabel>
            <MUISelect
                value={value}
                label={label}
                onChange={handleChange}
                sx={sx}
                MenuProps={{ classes: { paper: classes.selectPaper } }}
                input={(
                    <OutlinedInput
                        name={name}
                        id="outlined-simple"
                    />
                )}
            >
                {options.map(({ value, label }) => (
                    <MenuItem
                        key={value}
                        classes={{ root: classes.menuItemRoot }}
                        value={value}
                    >
                        <Trans>{label}</Trans>
                    </MenuItem>
                ))}
            </MUISelect>
            {helperText && (
                <FormHelperText error={error} className={helperTextClassName}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default withStyles(styles)(Select);
